"use strict";

jQuery(document).ready(function ($) {
  // Foundation initialization
  $(document).foundation();

  // Initialize Magnific Popup
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: ' mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });

  // Navigation
  var nav = $("nav#site-navigation");
  var openMenuBtn = $('button#openMenu');
  var primaryMobileMenuBtn = $('button#primary-mobile-menu');
  var closeMenuBtn = $('button#closeMenu');
  openMenuBtn.click(function () {
    nav.addClass("open");
  });
  primaryMobileMenuBtn.add(closeMenuBtn).click(function () {
    nav.removeClass("open");
  });

  // Check if element is scrolled into view
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + ($(window).height() + 200); // 200px offset for earlier response

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  // Handle navigation list items
  var ul = $("nav#site-navigation ul li ul");
  var li = $("nav#site-navigation ul li ul li");
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    $("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }

  // Handle closing navigation on outside click
  $(document).mouseup(function (e) {
    if (!nav.is(e.target) && nav.has(e.target).length === 0) {
      nav.removeClass('open');
    }
  });

  // Search functionality
  var openSearchBtn = $('.open-search-from');
  var deleteSearchBtn = $('#deleteSearch');
  openSearchBtn.click(function () {
    $('section').addClass("open-search");
    $('body').addClass("search-main");
  });
  deleteSearchBtn.click(function () {
    $('section').removeClass("open-search");
    $('body').removeClass("search-main");
  });

  // Handle fading elements on scroll
  $(window).on('scroll touchmove', function () {
    $('.fade-in').each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).addClass('animation-started');
      }
    });
  });

  // Handle navbar behavior on scroll
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $('header').outerHeight();
  $(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = $(window).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      $('header').removeClass('nav-down').addClass('nav-up');
    } else {
      if (st + $(window).height() < $(document).height()) {
        $('header').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  }
});

// Touch event setup
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};